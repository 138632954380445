import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useQuery, DocumentNode } from '@apollo/client';
import { QueryFunctionOptions } from '@apollo/client/react/types/types';

import { ResponseDataType } from 'components/TableView/types';
import { ILeaseSearch, ITimsSearch } from 'interfaces/ILeasesSearch';
import { IPropertySearch } from 'interfaces/IPropertySearch';
import { ISaleSearch } from 'interfaces/ISalesSearch';
import { IActivitiesSearch } from 'interfaces/IActivitiesSearch';
import { IFilesUploadSearch } from 'interfaces/IFileUpload';
import { EntityTypeMap, EntityType } from 'interfaces/IEntity';
import { ErrorLogger } from 'services/ErrorLogger';

interface UsePaginationProps extends QueryFunctionOptions {
  query: DocumentNode;
  getQueryVariables: GetQueryVariables;
  typeOfData: EntityType;
  onCompleted?: (data: ResponseDataType) => void;
  onInitialCall?: (data: ResponseDataType) => void;
  pageSize?: number;
  isActive?: boolean;
  onError?: () => void;
}

export type GetQueryVariables = (
  page?: number,
) => {
  search:
    | ITimsSearch
    | ILeaseSearch
    | IPropertySearch
    | ISaleSearch
    | IActivitiesSearch
    | IFilesUploadSearch;
};

const DEFAULT_PAGE_SIZE = 50;
const BOTTOM_PAGINATION_OFFSET = 100;

export const useLazyPagination = <T>({
  query,
  getQueryVariables,
  skip,
  onInitialCall,
  onCompleted,
  typeOfData,
  onError,
  isActive,
  pageSize,
  ...queryOptions
}: UsePaginationProps & { typeOfData: T }) => {
  type ItemType = EntityTypeMap[Extract<T, keyof EntityTypeMap>];

  const PAGE_SIZE = pageSize || DEFAULT_PAGE_SIZE;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [items, setItems] = useState<ItemType[]>([]);
  const [preventFetching, setPreventFetching] = useState(false);
  const [hasCalledInitially, setHasCalledInitially] = useState(false);

  const { data, loading, fetchMore, error, refetch } = useQuery<any>(query, {
    variables: {
      ...getQueryVariables(1),
      search: {
        ...getQueryVariables(1).search,
        page: { size: PAGE_SIZE, page: 1 },
      },
    },
    skip: skip,
    onError: () => {
      onError?.();
      setItems([]);
    },
    onCompleted: data => {
      setPreventFetching(false);
      const newItems = (data?.[typeOfData]?.results ?? []) as ItemType[];
      setItems(newItems);
      if (newItems.length < PAGE_SIZE) {
        setPreventFetching(true);
      }
      if (onInitialCall && !hasCalledInitially) {
        onInitialCall(data);
      }
      if (onCompleted) {
        onCompleted(data);
      }
      setHasCalledInitially(true);
    },
    ...queryOptions,
  });

  const loadNextPage = debounce(async () => {
    if (
      !data ||
      loading ||
      isLoadingNextPage ||
      preventFetching ||
      currentPage >= data?.[typeOfData].totalPages ||
      !data?.[typeOfData]?.results?.length
    ) {
      return;
    }

    try {
      setIsLoadingNextPage(true);
      const nextPage = currentPage + 1;

      const { data: nextData } = await fetchMore({
        variables: {
          ...getQueryVariables(nextPage),
          search: {
            ...getQueryVariables(nextPage).search,
            page: { size: PAGE_SIZE, page: nextPage },
          },
        },
      });
      const newItems = nextData?.[typeOfData]?.results || [];

      if (newItems?.length) {
        setItems(prevItems => [...prevItems, ...newItems]);

        if (newItems < PAGE_SIZE) {
          setPreventFetching(true);
        }
      }
      setCurrentPage(nextPage);
    } catch (e) {
      ErrorLogger.log(e as any, 'Unexpected error loading more data');
    } finally {
      setIsLoadingNextPage(false);
    }
  }, 300);

  useEffect(() => {
    const triggerLoadNextPage = async () => {
      if (!isActive || !data?.[typeOfData]?.results?.length) return;
      if (
        window.scrollY + window.innerHeight >=
        document.body.offsetHeight - BOTTOM_PAGINATION_OFFSET
      ) {
        await loadNextPage();
      }
    };

    window.addEventListener('scroll', triggerLoadNextPage);

    return () => {
      window.removeEventListener('scroll', triggerLoadNextPage);
    };
  }, [
    isActive,
    data,
    currentPage,
    isLoadingNextPage,
    loadNextPage,
    typeOfData,
  ]);

  return {
    data,
    items,
    error,
    isLoadingNextPage,
    loading,
    refetch,
    setCurrentPage,
    loadNextPage,
  };
};
