import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import Simplebar from 'simplebar';

import { ISearchOrder } from 'interfaces/ISearch';
import { EntityTypeMap } from 'interfaces/IEntity';

import { COLUMN_ID_SELECTION } from './Table';
import styles from './TableView.module.scss';
import { addHoverEditButton } from './editButtonTable';
import { createFixedHeader, toggleHeaderVisibility } from './fixedTableHeader';
interface Props {
  id: string;
  data: EntityTypeMap[keyof EntityTypeMap][];
  tableContainerRef: React.RefObject<HTMLDivElement>;
  tableRef: React.RefObject<HTMLTableElement>;
  editButtonWrapperRef: React.RefObject<HTMLDivElement>;
  fixedHeadingsScrollableRef: React.RefObject<HTMLDivElement>;
  fixedHeadingsRef: React.RefObject<HTMLDivElement>;
  isActive: boolean;
  isAllRowsSelected: boolean;
  toggleAllRowsSelected: (value?: boolean) => void;
  setPaginateOrder: (columnName: string) => void;
  setItemId: (itemId: string | undefined) => void;
  hasEditButton?: boolean;
  scrollClassName?: string;
  order?: ISearchOrder;
}
export const HorizontalScrollBar = ({
  id,
  tableContainerRef,
  tableRef,
  editButtonWrapperRef,
  fixedHeadingsScrollableRef,
  fixedHeadingsRef,
  isActive,
  scrollClassName,
  data,
  hasEditButton,
  order,
  toggleAllRowsSelected,
  isAllRowsSelected,
  setPaginateOrder,
  setItemId,
}: Props) => {
  const scrollWrapperRef = useRef<HTMLTableElement>(null);
  const scrollContentRef = useRef<HTMLTableElement>(null);

  /**
   * Checks if the table horizontal scroll should be `position: absolute`;
   * It will be true when we have any space between the end of the table and the bottom of the window.
   */
  const isAbsoluteHorizontalScroll = () => {
    if (!tableContainerRef.current) return false;

    const distanceTableToTop =
      tableContainerRef.current!.offsetTop +
      tableContainerRef.current!.getBoundingClientRect().height;

    const bottomWindowY = window.scrollY + window.innerHeight;
    return bottomWindowY > distanceTableToTop;
  };

  const handleHorizontalScroll = () => {
    const tableContainer = tableContainerRef.current;
    const scrollWrapper = scrollWrapperRef.current;

    if (!tableContainer || !scrollWrapper) return;

    const scrollContainer = scrollWrapper.parentElement!;

    // Apply the Simplebar plugin, so we have a better looking horizontal scrollbar
    new Simplebar(scrollWrapperRef.current!, {
      autoHide: false,
    });

    const simplebar = Simplebar.instances.get(scrollWrapperRef.current!);

    simplebar!.getScrollElement().addEventListener('scroll', (e: any) => {
      tableContainer!.scrollLeft = e.target.scrollLeft;
    });

    // Mimic the scrolling on the table with the simplebar container
    tableContainer.addEventListener('scroll', (e: any) => {
      simplebar!.getScrollElement().scrollLeft = e.target.scrollLeft;

      if (isAbsoluteHorizontalScroll()) {
        scrollContainer.style.left = `${e.target.scrollLeft}px`;
      }
    });
  };

  useEffect(() => {
    if (!isActive) return;

    const tableContainer = tableContainerRef.current;
    const table = tableRef.current;

    const scrollWrapper = scrollWrapperRef.current;
    const scrollContent = scrollContentRef.current;

    if (!tableContainer || !table || !scrollWrapper || !scrollContent) return;

    const scrollContainer = scrollWrapper.parentElement!;

    // border of the tableContainer
    const borderWidth = 1;

    // When the scroll container is fixed at the bottom of the page, we need to set its initial left
    // position the same as the table container, and also update the scrollWrapper and ScrollContent widths,
    // in order to have the same dimensions as the tableContainer and table.
    const updateScrollContainerFixedDimensions = () => {
      // decrease the width to keep the container's border visible at left and right.
      scrollWrapper.style.width = `${tableContainer.offsetWidth -
        borderWidth * 2}px`;

      scrollContent.style.width = `${table.offsetWidth}px`;

      // Add the borderWidth to move the scrollWrapper to the right, so the border will stay visible at the left side.
      scrollContainer.style.left = `${tableContainer!.getBoundingClientRect()
        .left + borderWidth}px`;
    };

    // When the scroll container is Absolute, it means that we have space between the end of the table and the bottom of the window.
    // This function will adjust the dimensions of the scroll container.
    const updateScrollContainerAbsoluteDimensions = () => {
      // do the same as for the fixed scroll container
      scrollWrapper.style.width = `${tableContainer.offsetWidth -
        borderWidth * 2}px`;
      scrollContent.style.width = `${table.offsetWidth}px`;

      // And change the left to be the same scroll position inherited from the table container.
      scrollContainer.style.left = `${tableContainer!.scrollLeft +
        borderWidth}px`;
    };

    const updateScrollContainerPositioning = () => {
      if (!tableContainer || !table || !scrollWrapper || !scrollContent) return;

      if (isAbsoluteHorizontalScroll()) {
        scrollWrapper.parentElement!.classList.add(
          styles['absolute-container'],
        );
        scrollClassName &&
          scrollWrapper.parentElement!.classList.remove(scrollClassName);
        updateScrollContainerAbsoluteDimensions();
      } else {
        scrollWrapper.parentElement!.classList.remove(
          styles['absolute-container'],
        );
        scrollClassName &&
          scrollWrapper.parentElement!.classList.add(scrollClassName);
        updateScrollContainerFixedDimensions();
      }
    };

    updateScrollContainerPositioning();
    handleHorizontalScroll();

    window.addEventListener('scroll', updateScrollContainerPositioning);
    window.addEventListener('resize', updateScrollContainerPositioning);
    return () => {
      window.removeEventListener('scroll', updateScrollContainerPositioning);
      window.removeEventListener('resize', updateScrollContainerPositioning);
    };
    // eslint-disable-next-line
  }, [data, isActive]);

  const handleFixedCheckBox = (columnId: string) => {
    if (columnId === COLUMN_ID_SELECTION) {
      toggleAllRowsSelected(!isAllRowsSelected);
    } else {
      setPaginateOrder(columnId);
    }
  };

  useEffect(() => {
    if (!isActive) return;

    const toggleFixedHeader = () => {
      toggleHeaderVisibility(fixedHeadingsRef.current!, tableRef.current!);
    };

    // eslint-disable-next-line
    let removeListenersEditButton: (() => void) | undefined = () => {};

    const handleTableFunctions = () => {
      createFixedHeader(
        tableRef.current!,
        tableContainerRef.current!,
        fixedHeadingsRef.current!,
        fixedHeadingsScrollableRef.current!,
        (columnId: string) => handleFixedCheckBox(columnId),
      );

      if (hasEditButton) {
        removeListenersEditButton = addHoverEditButton({
          tableElement: tableRef.current!,
          tableContainer: tableContainerRef.current!,
          editButtonWrapperRef: editButtonWrapperRef.current!,
          getItemId: setItemId,
        });
      }
    };

    const timeout = setTimeout(handleTableFunctions);
    window.addEventListener('resize', handleTableFunctions);
    window.addEventListener('scroll', toggleFixedHeader);

    return () => {
      timeout && clearTimeout(timeout);
      window.removeEventListener('resize', handleTableFunctions);
      window.removeEventListener('scroll', toggleFixedHeader);
      removeListenersEditButton && removeListenersEditButton();
    };

    // Need to execute this useEffect every time any of the dependencies below change, even if they are not
    // explicitly used on the createFixedHeader function.
    // On every update we need to recreate the Fixed header.
    // eslint-disable-next-line
  }, [id, isActive, order, data, isAllRowsSelected]);

  return (
    <div className={styles['scroll-container']}>
      <div
        ref={scrollWrapperRef}
        className={classNames(styles['scroll-wrapper'])}
      >
        <div ref={scrollContentRef} className={styles['scroll-content']} />
      </div>
    </div>
  );
};
