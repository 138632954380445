import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import CompanySearchCombo from 'components/CreateComps/CompanySearchCombo';
import FormControl from 'components/FormControl';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import RadioInput from 'components/Inputs/RadioInput';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import Checkbox from 'components/Checkbox';
import TextInput from 'components/Inputs/TextInput';
import NetEffectiveRentField from 'components/CreateComps/FormSection/LeaseForm/LeaseTerms/NetEffectiveRentField';
import FreeRentMonths from 'components/CreateComps/FormSection/FormFields/FreeRentMonths';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import {
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_PURSUIT_LABEL,
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH,
  I18N_PLATFORM_ROLES_COMPANY_PROMPT_PATH,
} from 'constants/i18n';
import { YES_NO_OPTIONS } from 'constants/commonOptions';
import { UNITS_MEASUREMENT_AREA } from 'constants/unitOfMeasurement';
import { SIZE_GROWTH_OPTIONS } from 'constants/expandingContracting';
import { BrokerCompanySuggestionType } from 'constants/BrokerCompanySuggestionType';
import { RentTypes } from 'constants/rentTypes';

import { convertToNumber } from 'utils/parsers/convertToNumber';
import { translateText } from 'utils/i18n';
import { IProperty } from 'interfaces/IProperty';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { IdName } from 'interfaces/IdName';
import { SEARCH_COMPANIES_PROPERTIES_QUERY } from 'graphql/common';
import { BUILD_OUT_TYPES_QUERY } from 'graphql/buildOut';
import {
  getFieldNameForLease,
  getFieldValueForLease,
} from 'utils/unitsOfMeasurement';
import { isMonthlyTimeMeasurement } from 'utils/leases';
import { currencyInputFormatter } from 'utils/formatters/currency';
import { getTaxAndOpexSuggestions } from 'utils/getTaxAndOpexSuggestions';
import { isUkProperty } from 'utils/properties';

import TIAllowance from '../../FormFields/TIAllowance/index';
import { getBrokersValue } from '../../FormFields/utils';
import { calculateTaxAndOpexTotal } from '../../PropertyForm/calculateFields';
import SelectProperty from '../../FormFields/SelectProperty';
import SizeFields from '../../FormFields/SizeFields';
import RentType from '../../FormFields/RentType';
import { useGetDefaultValue } from '../../SpaceForm/RequiredFields/hooks/useGetDefaultValue';
import { updateLeaseTaxAndOpexValues } from './utils';
import {
  calculateAdditionalConcessions,
  checkTaxOpexValid,
  getBrokerName,
  getExpenseFromCommencementYear,
  getYearFromCommencementDate,
} from '../utils';
import styles from '../../FormSection.module.scss';
import { LeaseFormSectionProps } from '..';
import EscalationFields from '../../FormFields/EscalationFields';
import { BrokerOptions } from '../../FormFields/BrokerOptions';
import { updateBooleanOptions } from '../../formUtils';

interface Props extends LeaseFormSectionProps {
  leaseCurrencySymbol: string;
  unitOfMeasurement: string;
  handleChangedSizeGrowth: () => void;
  handleTriggeredCalculation: () => void;
  updateLeaseBrokersProperty: (brokerField: string, brokers: any) => void;
  leaseProperty?: IProperty;
}

const LeaseTermsSection: React.FC<Props> = ({
  leaseCurrencySymbol,
  leaseInput,
  handleTriggeredCalculation,
  handleChangedSizeGrowth,
  unitOfMeasurement,
  updateLeaseBrokersProperty,
  updateLeaseProperty,
  leaseProperty,
}) => {
  const { t } = useTranslation();
  const { getDefaultValue } = useGetDefaultValue();

  const { data: buildOutTypes } = useQuery<{ buildOutTypes: IdName[] }>(
    BUILD_OUT_TYPES_QUERY,
  );
  const [taxOpexFieldsDisabled, setTaxOpexFieldsDisabled] = useState(false);
  const [isTaxOpexRequired, setIsTaxOpexRequired] = useState(false);
  const [hasTaxAndOpexFields, setHasTaxAndOpexFields] = useState(false);
  const [hasTaxThroughCommencement, setHasTaxThroughCommencement] = useState(
    false,
  );
  const [opexChangedManually, setOpexChangedManually] = useState(true);
  const [opexChangedByProperty, setOpexChangedByProperty] = useState(true);

  const measurementSystem =
    leaseInput?.measurementSystem || leaseInput?.property?.measurementSystem;

  const isPropertyUk = isUkProperty(leaseProperty);

  const calculateTaxAndOpex = (tax?: number | null, opex?: number | null) => {
    // calculate total tax&opex on tax or opex value changes
    const taxOpex = calculateTaxAndOpexTotal(tax, opex) || null;
    updateLeaseProperty('taxAndOpex', taxOpex);
    setOpexChangedManually(true);
    updateLeaseProperty('taxAndOpexChangedManually', true);
  };

  const updateOperatingExpenses = (value: string) => {
    const tax = convertToNumber(leaseInput.tax);
    const opex = convertToNumber(value);
    updateLeaseProperty('opex', value);
    calculateTaxAndOpex(tax, opex);
  };

  const updateTax = (value: string) => {
    const tax = convertToNumber(value);
    const opex = convertToNumber(leaseInput.opex);
    updateLeaseProperty('tax', value);
    calculateTaxAndOpex(tax, opex);
  };

  const onTaxAndOpexChange = (value: string) => {
    updateLeaseProperty('taxAndOpex', value);
    const hasValue = value.trim();
    if (hasValue) {
      updateLeaseProperty('tax', null);
      updateLeaseProperty('opex', null);
    }
    setOpexChangedManually(true);
    setOpexChangedByProperty(true);
    updateLeaseProperty('taxAndOpexChangedManually', true);
  };

  const updateAdditionalConcessions = (value: string) => {
    updateLeaseProperty('additionalConcessions', value);

    calculateAdditionalConcessions({
      additionalConcessions: value,
      leaseInput,
      measurementSystem,
      updateLeaseProperty,
    });
  };

  const updateAdditionalConcessionsPSF = (value: string) => {
    updateLeaseProperty(
      getFieldNameForLease(
        'additionalConcessionsPSF',
        leaseInput,
        measurementSystem,
      ),
      value,
    );

    calculateAdditionalConcessions({
      additionalConcessionsPSF: value,
      leaseInput,
      measurementSystem,
      updateLeaseProperty,
    });
  };

  useEffect(() => {
    if (isTaxOpexRequired) return;

    const isRequired = !checkTaxOpexValid(leaseInput, leaseProperty);
    setIsTaxOpexRequired(isRequired);
  }, [isTaxOpexRequired, leaseInput, leaseProperty]);

  useEffect(() => {
    // when tax&opex total is manually updated by the user
    // disable and clear tax & opex single fields
    const { taxAndOpex, tax, opex } = leaseInput;
    const hasOnlyTaxAndOpex = taxAndOpex && !tax && !opex;

    setHasTaxAndOpexFields(!!taxAndOpex || !!(tax || opex));

    if (!taxAndOpex && tax && opex) {
      updateLeaseProperty('taxAndOpex', tax + opex);
    }

    hasOnlyTaxAndOpex
      ? setTaxOpexFieldsDisabled(true)
      : setTaxOpexFieldsDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseInput]);

  useEffect(() => {
    const { timeMeasurement, commencementDate } = leaseInput;
    if (leaseProperty?.expenses && timeMeasurement) {
      const commencementYear = Number(
        getYearFromCommencementDate(commencementDate),
      );
      const taxAndOpexByPropertyValues = getDefaultValue(
        leaseProperty,
        commencementYear,
      );
      const newTaxAndOpenValue = isMonthlyTimeMeasurement(timeMeasurement)
        ? taxAndOpexByPropertyValues?.MONTHLY
        : taxAndOpexByPropertyValues?.ANNUAL;
      if (
        !hasTaxAndOpexFields ||
        !opexChangedByProperty ||
        !opexChangedManually
      ) {
        setOpexChangedByProperty(false);
        updateLeaseTaxAndOpexValues(
          updateLeaseProperty,
          null,
          null,
          newTaxAndOpenValue,
          false,
        );
      }
    } else if (!timeMeasurement && !opexChangedByProperty) {
      updateLeaseTaxAndOpexValues(updateLeaseProperty, null, null, null, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseProperty, leaseInput.timeMeasurement, leaseInput.commencementDate]);

  useEffect(() => {
    const { commencementDate } = leaseInput;
    if (leaseProperty?.expenses && commencementDate) {
      const leaseExpense = getExpenseFromCommencementYear(
        leaseInput,
        leaseProperty,
      );

      if (leaseExpense && leaseExpense.taxAndOpex) {
        setHasTaxThroughCommencement(!!leaseExpense);
      }
      if (!hasTaxAndOpexFields || !opexChangedManually) {
        updateLeaseTaxAndOpexValues(
          updateLeaseProperty,
          leaseExpense?.operatingExpenses,
          leaseExpense?.tax,
          leaseExpense?.taxAndOpex,
          false,
        );
        setOpexChangedManually(false);
      }
    } else if (commencementDate && !opexChangedManually) {
      updateLeaseTaxAndOpexValues(updateLeaseProperty, null, null, null, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseInput?.commencementDate, leaseProperty]);

  return (
    <>
      <EscalationFields
        formType="lease"
        isPropertyUk={isPropertyUk}
        escalation={leaseInput?.escalation ?? ''}
        escalationIncrement={leaseInput?.escalationIncrement ?? ''}
        escalationIncrementType={leaseInput?.escalationIncrementType}
        escalationType={leaseInput?.escalationType ?? ''}
        escalationCap={leaseInput?.escalationCap}
        escalationCollar={leaseInput?.escalationCollar}
        currencySymbol={leaseCurrencySymbol}
        onUpdateHandler={updateLeaseProperty}
        upwardOnly={leaseInput?.upwardOnly}
        multipleBasis={leaseInput?.multipleBasis}
      />
      <FreeRentMonths
        property={leaseProperty}
        onChange={updateLeaseProperty}
        value={leaseInput.freeRentMonths}
      />
      {!isPropertyUk && (
        <RentType
          property={leaseProperty}
          rentType={RentTypes.FREE_RENT_TYPE}
          onChange={updateLeaseProperty}
          selectedItem={leaseInput?.freeRentType}
        />
      )}
      <TIAllowance
        property={leaseProperty}
        onChange={updateLeaseProperty}
        currencySymbol={leaseCurrencySymbol}
        unitOfMeasurement={unitOfMeasurement}
        value={leaseInput.tenantImprovementAllowance}
      />
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.additionalConcessions`,
        )}
        wrapperClassName={styles['form-row']}
      >
        <CurrencyInput
          currencySymbol={leaseCurrencySymbol}
          iconPosition={'left'}
          id="additional-concessions"
          onChange={updateAdditionalConcessions}
          formatter={currencyInputFormatter}
          placeholder={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          value={leaseInput.additionalConcessions}
        />
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.additionalConcessionsPSF`,
          { unit: UNITS_MEASUREMENT_AREA[unitOfMeasurement] },
        )}
        wrapperClassName={styles['form-row']}
      >
        <div className={styles['large-input-container']}>
          <CurrencyInput
            currencySymbol={leaseCurrencySymbol}
            iconPosition={'left'}
            id="additional-concessions-psf"
            onChange={updateAdditionalConcessionsPSF}
            formatter={currencyInputFormatter}
            placeholder={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enter`,
            )}
            value={getFieldValueForLease(
              'additionalConcessionsPSF',
              leaseInput,
              measurementSystem,
            )}
          />
          <UnitOfMeasurementLegend unitOfMeasurement={unitOfMeasurement} />
        </div>
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.taxAndOpex`,
        )}
        wrapperClassName={styles['form-row']}
        required={hasTaxThroughCommencement || isTaxOpexRequired}
      >
        <div className={styles['large-input-container']}>
          <CurrencyInput
            currencySymbol={leaseCurrencySymbol}
            iconPosition={'left'}
            id="tax-and-opex"
            onChange={onTaxAndOpexChange}
            formatter={currencyInputFormatter}
            placeholder={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enter`,
            )}
            value={leaseInput.taxAndOpex}
            isRequired={hasTaxThroughCommencement}
          />
          <UnitOfMeasurementLegend unitOfMeasurement={unitOfMeasurement} />
          <RadioInput
            selectedItem={Number(leaseInput.taxAndOpex)}
            data={getTaxAndOpexSuggestions(leaseCurrencySymbol)}
            onChange={item => onTaxAndOpexChange(String(item?.value) || '')}
          />
        </div>
      </FormControl>
      <FormControl
        label={translateText(`${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.opex`)}
        wrapperClassName={styles['form-row']}
        disabled={taxOpexFieldsDisabled}
      >
        <div className={styles['large-input-container']}>
          <CurrencyInput
            currencySymbol={leaseCurrencySymbol}
            iconPosition={'left'}
            id="lease-opex"
            isDisabled={taxOpexFieldsDisabled}
            onChange={updateOperatingExpenses}
            formatter={currencyInputFormatter}
            placeholder={translateText(
              `${I18N_AVANT_PROPERTY_PURSUIT_LABEL}.enterQty`,
            )}
            value={leaseInput.opex}
          />
          <UnitOfMeasurementLegend
            unitOfMeasurement={unitOfMeasurement}
            disabled={taxOpexFieldsDisabled}
          />
          {isPropertyUk && (
            <Checkbox
              label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.cappedServiceCharge`)}
              onChange={checked =>
                updateLeaseProperty('cappedServiceCharges', checked)
              }
              selected={!!leaseInput.cappedServiceCharges}
              labelClassName={styles['checkbox-label']}
            />
          )}
        </div>
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.taxes`,
        )}
        wrapperClassName={styles['form-row']}
        disabled={taxOpexFieldsDisabled}
      >
        <div className={styles['large-input-container']}>
          <CurrencyInput
            currencySymbol={leaseCurrencySymbol}
            iconPosition={'left'}
            id="lease-tax"
            isDisabled={taxOpexFieldsDisabled}
            onChange={updateTax}
            formatter={currencyInputFormatter}
            placeholder={translateText(
              'avantProperties.pursuit.label.enterQty',
            )}
            value={leaseInput.tax}
          />
          <UnitOfMeasurementLegend
            unitOfMeasurement={unitOfMeasurement}
            disabled={taxOpexFieldsDisabled}
          />
        </div>
      </FormControl>
      <NetEffectiveRentField
        updateLeaseProperty={updateLeaseProperty}
        leaseCurrencySymbol={leaseCurrencySymbol}
        requiredFields={{
          NetEffectiveRentCustom: leaseInput.netEffectiveRentCustom,
          netEffectiveRent: leaseInput.netEffectiveRent,
          netEffectiveRentMonthly: leaseInput.netEffectiveRentMonthly,
          isMonthly: isMonthlyTimeMeasurement(leaseInput.timeMeasurement),
        }}
      />
      <NetEffectiveRentField
        updateLeaseProperty={updateLeaseProperty}
        leaseCurrencySymbol={leaseCurrencySymbol}
        isCustom={true}
        requiredFields={{
          netEffectiveRentCustom: leaseInput.netEffectiveRentCustom,
          netEffectiveRent: leaseInput.netEffectiveRent,
          netEffectiveRentMonthly: leaseInput.netEffectiveRentMonthly,
          isMonthly: isMonthlyTimeMeasurement(leaseInput.timeMeasurement),
        }}
      />
      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.buildOut`)}
        wrapperClassName={styles['form-row']}
      >
        <ChoiceInput
          selectedItem={{
            id: leaseInput.buildOutType?.id,
          }}
          data={buildOutTypes?.buildOutTypes ?? []}
          containerWrapperClassName={styles['radio-input-container']}
          onChange={item => {
            updateLeaseProperty('buildOutType', { id: item?.id });
          }}
        />
      </FormControl>

      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.furnished`)}
        wrapperClassName={styles['form-row']}
      >
        <ChoiceInput
          labelFieldName="id"
          selectedItem={{ name: leaseInput.furnished }}
          data={YES_NO_OPTIONS}
          containerWrapperClassName={styles['radio-input-container']}
          onChange={v =>
            updateLeaseProperty('furnished', updateBooleanOptions(v))
          }
        />
      </FormControl>
      <CompanySearchCombo
        btnLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.createTenant`)}
        label={t(`${I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH}.tenantRepBroker`)}
        inputId={'tenant-broker-input'}
        placeholder={t(
          `${I18N_PLATFORM_ROLES_COMPANY_PROMPT_PATH}.enterBrokerName`,
        )}
        wrapperClassName={styles['form-row']}
        companies={[
          { name: leaseInput?.['tenantBrokers']?.[0]?.company || '' },
        ]}
        aliasPlaceholder={(suggestionName: string) =>
          `Click to tag ${suggestionName} as a Tenant Rep Broker.`
        }
        onCompanySelection={value =>
          updateLeaseBrokersProperty('tenantBrokers', getBrokersValue(value))
        }
        onClearSelectedSuggestion={() => {
          updateLeaseBrokersProperty('tenantBrokers', null);
        }}
      />
      <FormControl indented>
        <BrokerOptions
          selectedItem={getBrokerName(leaseInput, 'tenantBrokers')}
          onChange={item =>
            updateLeaseBrokersProperty('tenantBrokers', {
              company: item.value,
            })
          }
          brokerCompanySuggestionType={BrokerCompanySuggestionType.LEASE}
          marketId={leaseProperty?.market?.id}
          propertyTypeId={leaseProperty?.propertyType?.id}
        />
      </FormControl>
      {leaseInput?.tenantBrokers?.[0]?.company && (
        <FormControl
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.tenantRepBrokerContact`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <TextInput
            fullWidth
            value={leaseInput?.tenantBrokers?.[0]?.name}
            inputClassName={styles['source-input']}
            placeholder={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enterTenantRepBrokerContact`,
            )}
            isLarge={true}
            onChange={item => {
              updateLeaseBrokersProperty('tenantBrokers', {
                company: leaseInput?.['tenantBrokers']?.[0]?.company ?? '',
                name: item ?? '',
              });
            }}
          />
        </FormControl>
      )}
      <CompanySearchCombo
        btnLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.createTenant`)}
        label={t(`${I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH}.landlordBroker`)}
        inputId={'landlord-broker-input'}
        placeholder={t(
          `${I18N_PLATFORM_ROLES_COMPANY_PROMPT_PATH}.enterBrokerName`,
        )}
        wrapperClassName={styles['form-row']}
        companies={[
          { name: leaseInput?.['landlordBrokers']?.[0]?.company || '' },
        ]}
        aliasPlaceholder={(suggestionName: string) =>
          `Click to tag ${suggestionName} as a Landlord Broker.`
        }
        onCompanySelection={value =>
          updateLeaseBrokersProperty('landlordBrokers', getBrokersValue(value))
        }
        onClearSelectedSuggestion={() => {
          updateLeaseBrokersProperty('landlordBrokers', null);
        }}
      />

      <FormControl indented>
        <BrokerOptions
          selectedItem={getBrokerName(leaseInput, 'landlordBrokers')}
          onChange={item =>
            updateLeaseBrokersProperty('landlordBrokers', {
              company: item.value,
            })
          }
          brokerCompanySuggestionType={BrokerCompanySuggestionType.LEASE}
          marketId={leaseProperty?.market?.id}
          propertyTypeId={leaseProperty?.propertyType?.id}
        />
      </FormControl>
      {leaseInput?.landlordBrokers?.[0]?.company && (
        <FormControl
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.landlordBrokerContact`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <TextInput
            fullWidth
            value={leaseInput?.landlordBrokers?.[0]?.name}
            inputClassName={styles['source-input']}
            placeholder={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enterLandlordBrokerContact`,
            )}
            isLarge={true}
            onChange={item => {
              updateLeaseBrokersProperty('landlordBrokers', {
                company: leaseInput?.['landlordBrokers']?.[0]?.company ?? '',
                name: item ?? '',
              });
            }}
          />
        </FormControl>
      )}
      <SelectProperty
        id="leases-property-prioraddress-id"
        initialValue={leaseInput?.priorAddress?.primaryAddress}
        label={translateText(
          `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.priorAddress`,
        )}
        value={{
          id: leaseInput.priorAddress?.id,
          primaryAddress: leaseInput.priorAddress?.primaryAddress,
          name: leaseInput.priorAddress?.name,
        }}
        graphqlParams={{
          query: SEARCH_COMPANIES_PROPERTIES_QUERY,
          field: 'name',
          resultDataName: 'propertiesCompanies',
          onlyProperties: true,
        }}
        parseResults={(data: IPropertyCompany[]): IPropertyCompany[] => {
          return data.map(propertyCompany => ({
            ...propertyCompany,
            name: propertyCompany.displayAddress || propertyCompany.name,
          }));
        }}
        onChange={value => {
          value.id = value.propertyId ?? value.id;
          value.primaryAddress = value.primaryAddress ?? value.name;
          updateLeaseProperty('priorAddress', value);
        }}
        onClear={() => {
          updateLeaseProperty('priorAddress', null);
        }}
      />

      <CompanySearchCombo
        label={translateText(
          `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.ownerAtTimeOfLease`,
        )}
        btnLabel={translateText(
          'avantPlatform.roles.company.action.createOwner',
        )}
        placeholder={translateText(
          'avantPlatform.roles.company.prompt.enterOwnerNames',
        )}
        wrapperClassName={styles['form-row']}
        inputId="lease-owner-at-time-of-lease"
        companies={leaseInput?.owners ?? []}
        isMultiple
        onCompanySelection={(value: IdName) => {
          const newValue = value?.custom || value;
          newValue?.id &&
            updateLeaseProperty('owners', [
              ...(leaseInput.owners || []),
              newValue,
            ]);
        }}
        onRemoveItem={(owner: IdName) => {
          updateLeaseProperty(
            'owners',
            (leaseInput.owners || []).filter(
              (value: IdName) => value.id !== owner.id,
            ),
          );
        }}
      />

      <SizeFields
        id="lease-prior-size"
        field="priorSize"
        input={leaseInput}
        labelName={translateText(
          `${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.priorSize`,
        )}
        property={leaseProperty}
        onChange={(field, value) => {
          updateLeaseProperty(field, value);
          handleTriggeredCalculation();
        }}
        unitOfMeasurement={unitOfMeasurement}
        disableCalculationSizes={true}
      />
      <FormControl
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.sizeGrowth`)}
        wrapperClassName={styles['form-row']}
      >
        <ChoiceInput
          id="lease-size-growth"
          data-testid="lease-size-growth"
          data={SIZE_GROWTH_OPTIONS}
          itemWrapperClassName={styles['form-choice-input']}
          selectedItem={(leaseInput.expandingContracting as IdName) ?? null}
          labelFieldName="name"
          onChange={item => {
            updateLeaseProperty('expandingContracting', item);
            handleChangedSizeGrowth();
          }}
        />
      </FormControl>
      <FormControl
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.note`)}
        wrapperClassName={styles['form-row']}
        isMultiline
      >
        <div className={styles['large-input-container']}>
          <textarea
            id="lease-comment"
            data-testid="lease-comment"
            placeholder={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.enterNote`,
            )}
            className={styles['comment-area']}
            onChange={e => updateLeaseProperty('comments', e.target.value)}
            value={leaseInput.comments || ''}
          />
        </div>
      </FormControl>
    </>
  );
};

export default LeaseTermsSection;
