import { isNil } from 'lodash';
import { CURRENCY_CODES_BY_COUNTRY } from 'constants/currencies';
import { COMMA } from 'constants/placeholders';
import { globalCurrencies } from 'contexts/CurrencyContext';
import { ICountry } from 'interfaces/ICountry';
import {
  abbreviateNumber,
  formatNumberWithCommas,
  getDecimalSeparator,
  removeLocaleFormatting,
} from 'utils/formatters/number';
import { fieldHasValue } from 'utils/objects';

export interface ICurrencyConfig {
  currencySymbol?: string;
  decimals?: number;
  abbreviateMillions?: boolean;
  ignoreDecimalsForInteger?: boolean;
}

/**
 * This function will abbreviate a currency value to millions, keeping the first decimal if it has.
 * Ex: 1000000 -> 1M, 1100000 -> 1.1M
 *
 * @param value
 */
export const abbreviateCurrency = (value: number) => {
  if (!value) return 0;

  return abbreviateNumber(value);
};

export const parseCurrencyToNumber = (value: string | number) => {
  return +removeLocaleFormatting(value.toString());
};

/**
 * Formats the input value to allow thousands and decimal separators as the user types in.
 */
export const currencyInputFormatter = (
  val?: string,
  permitDecimalsWithZero?: boolean,
  isInputFocused?: boolean,
): string => {
  if (!fieldHasValue(val)) return '';

  const value = val!;
  const numberValue = removeLocaleFormatting(value).toString();
  const decimalSeparator = getDecimalSeparator();

  if (value.endsWith(decimalSeparator)) {
    return `${formatNumberWithCommas(numberValue)}${decimalSeparator}`;
  }

  const [mainValue, decimals = ''] = numberValue.toString().split('.');
  const countOfDecimals =
    isInputFocused !== undefined && !isInputFocused ? 2 : 1;
  const decimalStr = permitDecimalsWithZero
    ? decimals.padEnd(countOfDecimals, '0')
    : decimals;

  return `${formatNumberWithCommas(mainValue)}${
    decimals ? `${decimalSeparator}${decimalStr}` : ''
  }`;
};

export const getCurrencySymbol = (code?: string): string => {
  const currency = globalCurrencies.find(currency => currency.code === code);
  return currency ? currency.symbol : '$';
};

export const getListOfCurrencySymbols = (currencyCodes?: string[]) => {
  if (!currencyCodes || !currencyCodes.length) return getCurrencySymbol();

  return Array.from(
    new Set(currencyCodes.map(code => getCurrencySymbol(code))),
  ).join(COMMA);
};

export const getCurrencySymbolFromCountries = (
  countries?: ICountry[],
): string => {
  const { US } = CURRENCY_CODES_BY_COUNTRY;
  const countriesCode = countries?.map(({ currencyCode }) => currencyCode);

  const currencyCodes = countriesCode?.length ? countriesCode : [US];

  const currencySymbols = Array.from(
    new Set(currencyCodes.map(getCurrencySymbol)),
  );
  const hasMany = currencySymbols.length > 1;
  return hasMany ? getCurrencySymbol(US) : currencySymbols[0];
};

/**
 * formatCurrency
 * ------------------------------------------------------------------------------
 * @description Formats a given floating number to a currency string. It inverts
 * the pattern automatically depending the type of currency and accepts optional
 * configurations.
 * @example
 *  // returns 117.711.719,80€
 *  formatCurrency(117711719.8, { currencySymbol: '€', decimals: 2 });
 *  // returns £117.70M
 *  formatCurrency(117711719.8, { currencySymbol: '£', abbreviateMillions: true }
 * @param {number} value - Value that will be formatted as currency
 * @param {string} config.currencySymbol - The currency symbol that will be used
 * @param {number} config.decimals - Number of decimals to round
 * @param {boolean} config.abbreviateMillions - Decide to abbreviate Millions
 * @returns {string}
 */
export const formatCurrency = (
  value: number | undefined,
  {
    currencySymbol = '$',
    decimals = 2,
    abbreviateMillions = false,
    ignoreDecimalsForInteger = false,
  }: ICurrencyConfig,
) => {
  if (isNil(value)) return '';

  const isInverted = currencySymbol === '€';
  const result = abbreviateMillions
    ? abbreviateNumber(value)
    : formatNumberWithCommas(
        value,
        ignoreDecimalsForInteger && value % 1 === 0 ? 0 : decimals,
      );

  return isInverted
    ? `${result}${currencySymbol}`
    : `${currencySymbol}${result}`;
};
