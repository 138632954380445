import { isEmpty } from 'lodash';
import { ILease } from 'interfaces/ILease';
import {
  EscalationType,
  LeaseRentPaymentDetails,
  LeaseRentType,
  LeaseTransactionType,
  LeaseTypeEnum,
} from 'constants/leases';
import { freeRentTypes } from 'constants/rentTypes';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import { IBroker } from 'interfaces/IBroker';
import { ITIM } from 'interfaces/ITIM';
import { IBrokerInput } from 'interfaces/inputs/IBrokerInput';
import { ICompany } from 'interfaces/ICompany';
import { ICompanyInput } from 'interfaces/inputs/ICompanyInput';
import { IPropertyLookupInput } from 'interfaces/inputs/IPropertyLookupInput';
import { parseEnumGraphQL } from 'utils/formatters/string';
import { IdName } from 'interfaces/IdName';
import ISale from 'interfaces/ISale';
import { IAvailability } from 'interfaces/IAvailability';
import { IAvailabilityInput } from 'interfaces/inputs/IAvailabilityInput';
import { ISaleInput } from 'interfaces/inputs/ISaleInput';
import { IMultiselectItem } from 'interfaces/IMultiselectItem';
import IIndustry from 'interfaces/IIndustry';
import { getFieldValueOrNull } from 'utils/objects';
import {
  convertDecimalToFloat,
  convertFloatToDecimal,
} from 'utils/formatters/number';
import { convertIPropertyIntoIPropertyLookupInput } from 'utils/properties';
import { convertICompanyIntoICompanyInput } from 'utils/company';
import { getDateISOString } from 'utils/date';
import {
  convertBrokerInputToIdName,
  convertIdNameToBrokerInput,
} from 'utils/formatters/broker';
import {
  getEscalationValueForMutation,
  isEscalationTypePercentage,
} from './FormFields/EscalationFields/utils';

const validateOwners = (owners: IdName[] | undefined) => {
  return owners?.length ? owners : null;
};

export const prepareLeaseInputForMutation = (leaseInput: ILeaseInput) => {
  delete leaseInput.additionalConcessionsPSF;
  delete leaseInput.additionalConcessionsPSM;
  delete leaseInput.currencyCode;
  delete leaseInput.netEffectiveRentMonthly;
  delete leaseInput?.property?.type;
  delete leaseInput?.sublessor?.mergedCompanyId;
  delete leaseInput?.sublessor?.investorTypeId;
  delete leaseInput?.tenantCompany?.mergedCompanyId;
  delete leaseInput?.netEffectiveRent;

  const isTypeIndexLinked =
    leaseInput?.escalationType === EscalationType.IndexLinked;

  const sublessor = isEmpty(leaseInput?.sublessor)
    ? null
    : leaseInput?.sublessor;

  const customValues = {
    baseRent: getFieldValueOrNull(leaseInput.baseRent),
    baseRentPerKw: getFieldValueOrNull(leaseInput.baseRentPerKw),
    signDate: getFieldValueOrNull(leaseInput.signDate),
    spaceUse: getFieldValueOrNull(leaseInput.spaceUse),
    commencementDate: getFieldValueOrNull(leaseInput.commencementDate),
    expirationDate: getFieldValueOrNull(leaseInput.expirationDate),
    netEffectiveRentCustom: getFieldValueOrNull(
      leaseInput.netEffectiveRentCustom,
    ),
    type: leaseInput.type && parseEnumGraphQL(LeaseTypeEnum, leaseInput.type),
    escalation: getEscalationValueForMutation(
      leaseInput?.escalation ?? '',
      leaseInput?.escalationType ?? '',
    ),
    escalationCap:
      isTypeIndexLinked && leaseInput?.escalationCap
        ? convertFloatToDecimal(leaseInput.escalationCap, 4)
        : null,
    escalationCollar:
      isTypeIndexLinked && leaseInput?.escalationCollar
        ? convertFloatToDecimal(leaseInput.escalationCollar, 4)
        : null,
    upwardOnly: leaseInput.upwardOnly,
    multipleBasis: leaseInput.multipleBasis,
    transactionType: leaseInput.transactionType
      ? parseEnumGraphQL(LeaseTransactionType, leaseInput.transactionType)
      : null,
    rentPaymentDetail:
      leaseInput.rentPaymentDetail &&
      parseEnumGraphQL(LeaseRentPaymentDetails, leaseInput.rentPaymentDetail),
    rentType:
      leaseInput.rentType &&
      parseEnumGraphQL(LeaseRentType, leaseInput.rentType),
    freeRentMonths: getFieldValueOrNull(leaseInput.freeRentMonths),
    ...(leaseInput.freeRentType && {
      freeRentType: {
        id: freeRentTypes[leaseInput.freeRentType as LeaseRentType],
        name: parseEnumGraphQL(LeaseRentType, leaseInput.freeRentType),
      },
    }),
    size: getFieldValueOrNull(leaseInput.size),
    officeSize:
      leaseInput?.officeSize &&
      getFieldValueOrNull(Number(leaseInput?.officeSize)),
    warehouseSf:
      leaseInput?.warehouseSf &&
      getFieldValueOrNull(Number(leaseInput?.warehouseSf)),
    loadingDocks:
      leaseInput?.loadingDocks &&
      getFieldValueOrNull(Number(leaseInput?.loadingDocks)),
    driveInDoors:
      leaseInput?.driveInDoors &&
      getFieldValueOrNull(Number(leaseInput?.driveInDoors)),
    parkingSpaces:
      leaseInput?.parkingSpaces &&
      getFieldValueOrNull(Number(leaseInput?.parkingSpaces)),
    trailerParkingSpaces:
      leaseInput?.trailerParkingSpaces &&
      getFieldValueOrNull(Number(leaseInput?.trailerParkingSpaces)),
    sublessor:
      leaseInput?.sublessor && leaseInput?.type !== LeaseTypeEnum.SUBLEASE
        ? null
        : sublessor,
    expandingContracting: leaseInput?.expandingContracting ?? null,
    owners: validateOwners(leaseInput.owners),
    furnished: leaseInput?.furnished ?? false,
  };

  const {
    baseRentFullServiceEquiv, // eslint-disable-line
    ...inputWithoutBaseRentFullServiceEquiv
  } = { ...leaseInput };
  const finalLoad = {
    ...inputWithoutBaseRentFullServiceEquiv,
    ...customValues,
  };

  if (finalLoad?.areaMeasurement === 'sqm') {
    delete finalLoad?.size;
    delete finalLoad?.officeSize;
    delete finalLoad?.warehouseSf;
  } else {
    delete finalLoad?.sizeMt;
    delete finalLoad?.officeSizeMt;
    delete finalLoad?.warehouseMt;
  }
  delete finalLoad.areaMeasurement;

  return finalLoad;
};

export const prepareSaleInputForMutation = (saleInput: ISaleInput) => {
  delete saleInput.currencyCode;
  delete saleInput?.property?.marketId;
  delete saleInput?.property?.propertyTypeId;

  const saleData = { ...saleInput };

  Object.keys(saleData).forEach(key => {
    saleData[key] = getFieldValueOrNull(saleData[key]);
  });

  if (!saleData.sf && !saleData.squareMeters) {
    saleData.sf = saleData.sf || null;
    saleData.squareMeters = saleData.squareMeters || null;
  } else {
    saleData.sf = saleData.sf || undefined;
    saleData.squareMeters = saleData.squareMeters || undefined;
  }

  if (!isEmpty(saleData.sellersBrokers)) {
    saleData.sellersBrokers = convertIdNameToBrokerInput(
      saleData.sellersBrokers!,
    );
  }

  if (!isEmpty(saleData.buyersBrokers)) {
    saleData.buyersBrokers = convertIdNameToBrokerInput(
      saleData.buyersBrokers!,
    );
  }

  return saleData;
};

export const convertIBrokerIntoIBrokerInput = (
  broker: IBroker,
): IBrokerInput => {
  return {
    name: broker.name,
    company: broker.company?.name,
  };
};

export const getEscalationValueForInput = (
  leaseInput: ILeaseInput,
): string | null | undefined => {
  if (leaseInput.escalation) {
    const escalationNumber = Number(leaseInput.escalation);

    return isEscalationTypePercentage(leaseInput?.escalationType)
      ? String(convertDecimalToFloat(escalationNumber, 2))
      : leaseInput.escalation;
  }
  return leaseInput.escalation;
};

export const convertILeaseIntoILeaseInput = (lease: ILease): ILeaseInput => {
  if (!lease) return {};

  const landlordBrokers: IBrokerInput[] =
    lease.landlordBrokers?.map<IBrokerInput>(brokr =>
      convertIBrokerIntoIBrokerInput(brokr),
    ) || [];
  const tenantBrokers: IBrokerInput[] =
    lease.tenantBrokers?.map<IBrokerInput>(brokr =>
      convertIBrokerIntoIBrokerInput(brokr),
    ) || [];
  const tenantRepBrokers: IBrokerInput[] =
    lease.tenantRepBrokers?.map<IBrokerInput>(brokr =>
      convertIBrokerIntoIBrokerInput(brokr),
    ) || [];
  const tenantCompany: ICompanyInput = convertICompanyIntoICompanyInput(
    lease.tenantCompany,
  );
  const sublessor: ICompanyInput = convertICompanyIntoICompanyInput(
    lease.sublessor,
  );
  const property: IPropertyLookupInput = convertIPropertyIntoIPropertyLookupInput(
    lease.property,
  );
  const priorAddress: IPropertyLookupInput = convertIPropertyIntoIPropertyLookupInput(
    lease.priorAddress,
  );

  const freeRentType = lease?.freeRentType?.name ?? null;

  const fields: { [key: string]: any } = {
    id: lease.id,
    additionalConcessions: lease.additionalConcessions,
    additionalConcessionsPSF: lease.additionalConcessionsPSF,
    additionalConcessionsPSM: lease.additionalConcessionsPSM,
    buildOutType: lease.buildOutType,
    expandingContracting: lease.expandingContracting,
    tenantCompany,
    property,
    priorAddress,
    comments: lease.comments,
    landlordBrokers,
    sublessor,
    tenantBrokers,
    type: lease.type,
    confidential: lease.confidential,
    furnished: lease.furnished ?? false,
    transactionType: lease.transactionType,
    spaceUse: lease.spaceUse,
    rentPaymentDetail: lease.rentPaymentDetail,
    rentType: lease.rentType,
    signDate: lease.signDate,
    cappedServiceCharges: lease.cappedServiceCharges,
    commencementDate: lease.commencementDate,
    termInMonths: lease.termInMonths,
    expirationDate: lease.expirationDate,
    size: lease.size,
    sizeMt: lease.sizeMt,
    officeSize: lease.officeSize,
    officeSizeMt: lease.officeSizeMt,
    warehouseSf: lease.warehouseSf,
    warehouseMt: lease.warehouseMt,
    priorSize: lease.priorSize,
    floor: lease.floor,
    leaseOptions: lease.leaseOptions,
    financialStatus: lease.financialStatus,
    suite: lease.suite,
    baseRent: lease.baseRent,
    baseRentPerKw: lease.baseRentPerKw,
    baseRentFullServiceEquiv: lease.baseRentFullServiceEquiv,
    timeMeasurement: lease.timeMeasurement,
    escalationIncrement: lease.escalationIncrement,
    escalationIncrementType: lease.escalationIncrementType
      ? { id: lease.escalationIncrementType.id }
      : null,
    escalationType: lease.escalationType,
    escalation: lease.escalation,
    escalationCap: lease.escalationCap
      ? String(convertDecimalToFloat(lease.escalationCap, 2))
      : null,
    escalationCollar: lease.escalationCollar
      ? String(convertDecimalToFloat(lease.escalationCollar, 2))
      : null,
    upwardOnly: lease.upwardOnly,
    multipleBasis: lease.multipleBasis,
    freeRentMonths: lease.freeRentMonths,
    freeRentType: freeRentType,
    netEffectiveRentCustom: lease.netEffectiveRentCustom,
    netEffectiveRent: lease.netEffectiveRent,
    netEffectiveRentMonthly: lease.netEffectiveRentMonthly,
    tenantImprovementAllowance: lease.tenantImprovementAllowance,
    taxAndOpex: lease.taxAndOpex,
    tax: lease.tax,
    opex: lease.opex,
    currencyCode: lease.currencyCode,
    tenantRepBrokers,
    tenantInMarketId: lease.tenantInMarketId,
    tenantInMarketType: lease.tenantInMarketType,
    tenantInMarketSubtype: lease.tenantInMarketSubtype,
    tenantInMarketSizeMax: lease.tenantInMarketSizeMax,
    tenantInMarketSizeMin: lease.tenantInMarketSizeMin,
    tenantInMarketSizeMaxMt: lease.tenantInMarketSizeMaxMt,
    tenantInMarketSizeMinMt: lease.tenantInMarketSizeMinMt,
    tenantInMarketComments: lease.tenantInMarketComments,
    tenantInMarketLastDiscussedDate: lease.tenantInMarketLastDiscussedDate,
    cleaningIncluded: lease.cleaningIncluded,
    loadingDocks: lease.loadingDocks,
    driveInDoors: lease.driveInDoors,
    parkingSpaces: lease.parkingSpaces,
    trailerParkingSpaces: lease.trailerParkingSpaces,
    securityDeposit: lease.securityDeposit,
    securityDepositType: lease.securityDepositType,
    owners: lease.owners,
  };

  Object.keys(fields).forEach(key => {
    fields[key] = getFieldValueOrNull(fields[key]);
  });

  fields.escalation = getEscalationValueForInput(fields);

  return fields;
};

export const convertIdName = (item: Partial<IdName> | undefined) => ({
  id: item?.id,
  name: item?.name,
});

export const convertIdNameArray = (itemArray: IdName[]) =>
  itemArray.map(item => convertIdName(item));

export const convertISaleIntoISaleInput = (sale?: ISale): ISaleInput => {
  if (!sale) return {};

  const property: IPropertyLookupInput = convertIPropertyIntoIPropertyLookupInput(
    sale.property,
    true,
  );
  const saleSellers: IdName[] =
    sale.sellers?.map<ICompany>(convertIdName) || [];
  const saleBuyers: IdName[] = sale.buyers?.map<IdName>(convertIdName) || [];

  const sellersBrokers: IdName[] = convertBrokerInputToIdName(
    sale.sellersBrokers!,
  );

  const buyersBrokers: IdName[] = convertBrokerInputToIdName(
    sale.buyersBrokers!,
  );

  const industriesOfTenants: IdName[] =
    sale.industriesOfTenants?.map(convertIdName) || [];
  const underbidders: IdName[] =
    sale.underbidders?.map<ICompany>(convertIdName) || [];
  const assetProfile = convertIdName(sale.assetProfile);
  const type = convertIdName(sale.type);

  return {
    id: sale.id,
    property,
    partOfPortfolio: sale.partOfPortfolio,
    dealId: sale.dealId,
    numberOfBuildings: sale.numberOfBuildings,
    pricePerSF: sale.pricePerSFRaw,
    pricePerUnit: sale.pricePerUnit,
    price: sale.price,
    capRate: sale.capRate,
    sf: sale.sf,
    squareMeters: sale.squareMeters,
    suite: sale.suite,
    floor: sale.floor,
    date: sale.date,
    occupancy: sale.occupancy,
    dateOnMarket: sale.dateOnMarket,
    saleSellers,
    sellersBrokers,
    buyersBrokers,
    saleBuyers,
    industriesOfTenants,
    underbidders,
    assetProfile,
    type,
    comments: sale.comments,
    partialInvVol: sale.partialInvVol,
    interestPercent: sale.interestPercent,
    isConvertedSale: sale.isConvertedSale,
    currencyCode: sale.currencyCode,
    measurementSystem: sale.country?.code || sale.measurementSystem,
    tenureType: sale.tenureType,
    longLeaseHoldExpiry: sale.longLeaseHoldExpiry,
    longLeaseHoldGroundRent: sale.longLeaseHoldGroundRent,
    longLeaseHoldServiceCharge: sale.longLeaseHoldServiceCharge,
    longLeaseHoldAdminCharge: sale.longLeaseHoldAdminCharge,
    units: sale.units,
    waultToBreakDate: sale.waultToBreakDate,
    vacantPossession: sale.vacantPossession,
    waultToExpiry: sale.waultToExpiry,
    totalRent: sale.totalRent,
  };
};

export const convertIndustriesToMultiselectItems = (industries: IIndustry[]) =>
  industries.reduce(
    (prev: IMultiselectItem[], { categoryId, category, id, name }, index) => {
      const categoryIndex = prev.findIndex(e => e.name === category);
      if (categoryIndex !== -1) {
        prev[categoryIndex].subItems = [
          ...prev[categoryIndex].subItems,
          { id, name },
        ];
        return prev;
      } else {
        return [
          ...prev,
          {
            id: categoryId !== undefined ? categoryId : index,
            name: category,
            subItems: [{ id, name }],
          },
        ];
      }
    },
    [],
  );

export const convertMultiselectItemsToIndustries = (
  options: IMultiselectItem[],
) =>
  options?.reduce(
    (prev: IIndustry[], { id: categoryId, name: category, subItems }) => {
      const industries: IIndustry[] = subItems.map(({ id, name }) => ({
        id,
        name,
        categoryId,
        category,
      }));
      return [...prev, ...industries];
    },
    [],
  );

export const convertIAvailabilityIntoIAvailabilityInput = (
  availability: IAvailability,
): IAvailabilityInput => {
  if (!availability) return {};

  const property: IPropertyLookupInput = convertIPropertyIntoIPropertyLookupInput(
    availability.property,
  );

  const landlordBrokerCompany: IdName = {
    name: availability.landlordBrokerCompany?.name,
  };

  return {
    ...availability,
    availableSpaceMt: undefined,
    availableSpaceMinMt: undefined,
    availableSpaceMaxMt: undefined,
    floorContiguousSpaceMt: undefined,
    buildingContiguousSpaceMt: undefined,
    property,
    landlordBrokerCompany,
  };
};

export const convertTimBeforeLoad = (tim: ITIM | undefined) => {
  if (!tim) return;

  const {
    activeSinceDate,
    lastDiscussedDate,
    exitDate,
    targetCommencementDate,
    ...rest
  } = tim;

  const result: Partial<ITIM> = { ...rest };

  if (activeSinceDate) {
    result.activeSinceDate = getDateISOString(activeSinceDate);
  }
  if (lastDiscussedDate) {
    result.lastDiscussedDate = getDateISOString(lastDiscussedDate);
  }
  if (exitDate) {
    result.exitDate = getDateISOString(exitDate);
  }
  if (targetCommencementDate) {
    result.targetCommencementDate = getDateISOString(targetCommencementDate);
  }

  return result;
};
