import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
  UnitOfMeasurementCode,
  UNITS_OF_MEASUREMENT_CODE_MAPPING,
} from 'constants/unitOfMeasurement';
import { CountryCodes } from 'constants/countryCodes';
import {
  UnitsOfMeasurementCountryConfigType,
  UnitsOfMeasurementModelType,
} from 'interfaces/unitOfMeasurement';
import { IPropertyCommon } from 'interfaces/IPropertyCommon';
import {
  INDUSTRIAL_MAPPING,
  LEASE_MAPPING,
  OFFICE_MAPPING,
  LIFE_SCIENCE_MAPPING,
  DATA_CENTER_MAPPING,
  PROPERTY_MAPPING,
  SALE_MAPPING,
  MULTIFAMILY_MAPPING,
  AVAILABILITY_MAPPING,
} from './models';
import { ILease } from 'interfaces/ILease';
import { ILeaseInput } from 'interfaces/inputs/ILeaseInput';
import ISale from 'interfaces/ISale';
import { ISaleInput } from 'interfaces/inputs/ISaleInput';
import { ICountry } from 'interfaces/ICountry';
import { IAvailability } from 'interfaces/IAvailability';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import { IProperty } from 'interfaces/IProperty';
import { IAvailabilityInput } from 'interfaces/inputs/IAvailabilityInput';
import { IPricingQuoteInput } from 'interfaces/inputs/IPricingQuoteInput';
import { IPricingQuote } from 'interfaces/IPricingQuote';

export const ModelConfiguration: {
  [key: string]: UnitsOfMeasurementModelType[];
} = {
  [ModelsWithUnitsOfMeasurement.Property]: PROPERTY_MAPPING,
  [ModelsWithUnitsOfMeasurement.Office]: OFFICE_MAPPING,
  [ModelsWithUnitsOfMeasurement.LifeScience]: LIFE_SCIENCE_MAPPING,
  [ModelsWithUnitsOfMeasurement.DataCenter]: DATA_CENTER_MAPPING,
  [ModelsWithUnitsOfMeasurement.Industrial]: INDUSTRIAL_MAPPING,
  [ModelsWithUnitsOfMeasurement.Multifamily]: MULTIFAMILY_MAPPING,
  [ModelsWithUnitsOfMeasurement.Lease]: LEASE_MAPPING,
  [ModelsWithUnitsOfMeasurement.Sale]: SALE_MAPPING,
  [ModelsWithUnitsOfMeasurement.Availability]: AVAILABILITY_MAPPING,
};

export type PropertyEntitiesType =
  | ModelsWithUnitsOfMeasurement.Office
  | ModelsWithUnitsOfMeasurement.Industrial
  | ModelsWithUnitsOfMeasurement.DataCenter
  | ModelsWithUnitsOfMeasurement.Property
  | ModelsWithUnitsOfMeasurement.Multifamily
  | ModelsWithUnitsOfMeasurement.LifeScience;

const getUnitOfMeasurementConfigForModel = (
  fieldKey: string,
  model: ModelsWithUnitsOfMeasurement,
  measurementSystem?: string,
): UnitsOfMeasurementCountryConfigType | null => {
  const modelConfig = ModelConfiguration[model];

  const fieldConfig: UnitsOfMeasurementModelType | undefined = modelConfig.find(
    config => config.key === fieldKey,
  );

  const unitOfMeasurementCode =
    UNITS_OF_MEASUREMENT_CODE_MAPPING[measurementSystem || CountryCodes.US];

  return fieldConfig?.config?.[unitOfMeasurementCode] || null;
};

export const getUnitOfMeasurement = (
  fieldKey: string,
  model: ModelsWithUnitsOfMeasurement,
  measurementSystem?: string,
  defaultUnit: string = UnitOfMeasurement.sf,
) => {
  const fieldConfig = getUnitOfMeasurementConfigForModel(
    fieldKey,
    model,
    measurementSystem,
  );
  return fieldConfig?.unit || defaultUnit;
};

const getFieldName = (
  fieldKey: string,
  model: ModelsWithUnitsOfMeasurement,
  measurementSystem?: string,
) => {
  const fieldConfig = getUnitOfMeasurementConfigForModel(
    fieldKey,
    model,
    measurementSystem,
  );
  return fieldConfig?.field || fieldKey;
};

export const getUnitOfMeasurementForProperty = (
  fieldKey: string,
  model: PropertyEntitiesType,
  measurementSystem: string = UnitOfMeasurementCode.US,
  defaultUnit: string = UnitOfMeasurement.sf,
) => getUnitOfMeasurement(fieldKey, model, measurementSystem, defaultUnit);

export const getMeasurementSystemCode = (countries?: ICountry[]) => {
  const { US } = UnitOfMeasurementCode;
  const systemCodes = countries?.map(
    ({ code }) => UNITS_OF_MEASUREMENT_CODE_MAPPING[code],
  );
  const hasManyAndUS =
    systemCodes && systemCodes.length > 1 && systemCodes!.includes(US);

  return hasManyAndUS || !systemCodes?.length ? US : systemCodes![0];
};

export const getUnitOfMeasurementForLease = (
  fieldKey: string,
  measurementSystem?: string,
  defaultUnit: string = UnitOfMeasurement.sf,
) => {
  return getUnitOfMeasurement(
    fieldKey,
    ModelsWithUnitsOfMeasurement.Lease,
    measurementSystem,
    defaultUnit,
  );
};

export const getFieldValueForProperty = (
  fieldKey: string,
  property: IPropertyCommon,
  model: PropertyEntitiesType = ModelsWithUnitsOfMeasurement.Property,
) => {
  const fieldConfig = getUnitOfMeasurementConfigForModel(
    fieldKey,
    model,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

  switch (model) {
    case ModelsWithUnitsOfMeasurement.Office:
      return property?.office?.[fieldConfig?.field || fieldKey] || null;
    case ModelsWithUnitsOfMeasurement.Industrial:
      return property?.industrial?.[fieldConfig?.field || fieldKey] || null;
    case ModelsWithUnitsOfMeasurement.DataCenter:
      return property?.dataCenter?.[fieldConfig?.field || fieldKey] || null;
    case ModelsWithUnitsOfMeasurement.Multifamily:
      return property?.multifamily?.[fieldConfig?.field || fieldKey] || null;
    case ModelsWithUnitsOfMeasurement.LifeScience:
      return property?.lifeScience?.[fieldConfig?.field || fieldKey] || null;
    default:
      return property[fieldConfig?.field || fieldKey] || null;
  }
};

export const getFieldValueForLease = (
  fieldKey: string,
  lease: ILease | ILeaseInput,
  measurementSystem?: string,
) => {
  const fieldConfig = getUnitOfMeasurementConfigForModel(
    fieldKey,
    ModelsWithUnitsOfMeasurement.Lease,
    measurementSystem ||
      lease.country?.code ||
      lease.property?.propertyCountry?.code ||
      lease.measurementSystem,
  );

  return lease[fieldConfig?.field || fieldKey] != null
    ? lease[fieldConfig?.field || fieldKey]
    : null;
};

export const getFieldValueForPriceQuote = (
  fieldKey: string,
  priceQuote: IPricingQuoteInput | IPricingQuote,
  measurementSystem?: string,
) => {
  const fieldConfig = getUnitOfMeasurementConfigForModel(
    fieldKey,
    ModelsWithUnitsOfMeasurement.Lease,
    measurementSystem ||
      priceQuote.property?.propertyCountry?.code ||
      priceQuote.property?.measurementSystem,
  );

  return priceQuote[fieldConfig?.field || fieldKey] || null;
};

export const getFieldNameForProperty = (
  fieldKey: string,
  property: IPropertyCommon,
  model: PropertyEntitiesType = ModelsWithUnitsOfMeasurement.Property,
): string =>
  getFieldName(
    fieldKey,
    model,
    property?.propertyCountry?.code || property?.measurementSystem,
  );

export const getFieldNameByMeasurementSystem = (
  fieldKey: string,
  model: PropertyEntitiesType,
  measurementSystem: string = UnitOfMeasurementCode.US,
) => getFieldName(fieldKey, model, measurementSystem);

export const getFieldNameForLease = (
  fieldKey: string,
  lease: ILease | ILeaseInput,
  measurementSystem?: string,
): string =>
  getFieldName(
    fieldKey,
    ModelsWithUnitsOfMeasurement.Lease,
    measurementSystem ||
      lease.country?.code ||
      lease.measurementSystem ||
      lease.property?.propertyCountry?.code ||
      lease.property?.measurementSystem,
  );

export const getFieldNameForSale = (
  fieldKey: string,
  sale: ISale | ISaleInput,
  measurementSystem?: string,
): string =>
  getFieldName(
    fieldKey,
    ModelsWithUnitsOfMeasurement.Sale,
    measurementSystem ||
      sale.country?.code ||
      sale.property?.propertyCountry?.code ||
      sale.measurementSystem,
  );

export const getFieldValueForSale = (
  fieldKey: string,
  sale: ISale | ISaleInput,
  measurementSystem?: string,
) => {
  const fieldConfig = getUnitOfMeasurementConfigForModel(
    fieldKey,
    ModelsWithUnitsOfMeasurement.Sale,
    measurementSystem ||
      sale.country?.code ||
      sale.property?.propertyCountry?.code ||
      sale.measurementSystem,
  );

  return sale[fieldConfig?.field || fieldKey] || null;
};

export const isUsingMeters = (unitOfMeasurement?: string) =>
  ([UnitOfMeasurement.sm, UnitOfMeasurement.meter] as string[]).includes(
    unitOfMeasurement || '',
  );

export const getIndustrialFieldValue = (
  field: string,
  property: IProperty | IPropertyInput,
) =>
  getFieldValueForProperty(
    field,
    property,
    ModelsWithUnitsOfMeasurement.Industrial,
  );

export const getIndustrialFieldName = (
  field: string,
  property: IProperty | IPropertyInput,
) =>
  getFieldNameForProperty(
    field,
    property,
    ModelsWithUnitsOfMeasurement.Industrial,
  );

export const getFieldValueForAvailability = (
  fieldKey: string,
  availability: IAvailability | IAvailabilityInput,
  measurementSystem?: string,
) => {
  const fieldConfig = getUnitOfMeasurementConfigForModel(
    fieldKey,
    ModelsWithUnitsOfMeasurement.Availability,
    measurementSystem ||
      availability.property?.propertyCountry?.code ||
      availability.property?.measurementSystem,
  );

  return availability[fieldConfig?.field || fieldKey] || null;
};

export const getFieldNameForAvailability = (
  field: string,
  availability: IAvailability | IAvailabilityInput,
) =>
  getFieldName(
    field,
    ModelsWithUnitsOfMeasurement.Availability,
    availability?.property?.propertyCountry?.code ||
      availability?.property?.measurementSystem,
  );
