import React, { createContext, useState } from 'react';
import { COUNTRIES_QUERY } from 'graphql/countries/queries';
import { useQuery } from '@apollo/client';
import { ICountriesResponse, ICountry } from 'interfaces/ICountry';
import AuthService from 'services/Auth';

import { authContext } from './AuthContext';

interface CountriesInterface {
  isLoading: boolean;
  countries: ICountry[];
  currencyCodeSuggestions: CurrencyCodeSuggestion[];
  getCurrencyCode: (countryCode: string) => string | undefined;
}

interface CurrencyCodeSuggestion {
  label: string;
  value: string;
}

interface CountryCurrencyCode {
  code: string;
  currencyCode: string;
}

export const countriesContext = createContext<CountriesInterface>({
  isLoading: true,
  countries: [],
  currencyCodeSuggestions: [],
  getCurrencyCode: () => '',
});

const { Provider } = countriesContext;

type PropTypes = {
  children: React.ReactNode;
};

export const CountriesProvider: React.FC<PropTypes> = (props: PropTypes) => {
  const { user } = React.useContext(authContext);

  const [countries, setCountries] = useState<ICountry[]>([]);
  const [currencyCodeSuggestions, setCurrencyCodeSuggestions] = useState<
    CurrencyCodeSuggestion[]
  >([]);
  const [countryCurrencyCodes, setCountryCurrencyCodes] = useState<
    CountryCurrencyCode[]
  >([]);

  const { loading: isLoading } = useQuery<ICountriesResponse>(COUNTRIES_QUERY, {
    skip: !user.email || !AuthService.isOktaTokensReady(),
    onCompleted: data => {
      const countries = data?.countries ?? [];
      setCountries(countries);
      const suggestions: CurrencyCodeSuggestion[] = [];
      const countryCurrencyCodes: CountryCurrencyCode[] = [];
      countries.forEach(element => {
        suggestions.push({
          label: element.currencyCode,
          value: element.currencyCode,
        });
        countryCurrencyCodes.push({
          code: element.code,
          currencyCode: element.currencyCode,
        });
      });
      setCurrencyCodeSuggestions(suggestions);
      setCountryCurrencyCodes(countryCurrencyCodes);
    },
  });

  const getCurrencyCode = (countryCode: string) => {
    return countryCurrencyCodes.find(country => country.code === countryCode)
      ?.currencyCode;
  };

  return (
    <Provider
      value={{ isLoading, countries, currencyCodeSuggestions, getCurrencyCode }}
    >
      {props.children}
    </Provider>
  );
};
