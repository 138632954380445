import { gql } from '@apollo/client';
import { USER_INFO_FRAGMENT } from 'graphql/user/fragments';
import { SUMMARY_REPORT_FRAGMENT } from 'graphql/files/fragments';

export const CREATE_FILE = gql`
  mutation createFile($file: Upload!, $userId: Int!, $method: String) {
    createFile(file: $file, userId: $userId, method: $method) {
      id
      filename
      createdAt
      processedAt
      updatedAt
      error
      resultData {
        rowNumber
        message
        errors
        id
      }
      ...summaryReport
    }
  }
  ${SUMMARY_REPORT_FRAGMENT}
`;

export const UPLOAD_PROFILE_PIC_MUTATION = gql`
  mutation uploadProfilePic($file: Upload!) {
    uploadProfile(file: $file) {
      ... on User {
        ...userInfo
      }
      ... on UserError {
        message
      }
    }
  }
  ${USER_INFO_FRAGMENT}
`;

export const CANCEL_FILE_UPLOAD = gql`
  mutation stopFileUpload($fileId: Int!) {
    stopFileUpload(fileId: $fileId) {
      id
      processedAt
      error
    }
  }
`;

export const GENERATE_POWER_POINT = gql`
  mutation createPowerPoint($params: CreatePowerPointFileInput!) {
    createPowerPoint(params: $params)
  }
`;
