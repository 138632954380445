import React, { useState } from 'react';

import AYLink from 'components/AYLink';
import ModalMarketsLinks from 'components/MultipleMarketsLink/ModalMarketsLinks';
import { IMarket } from 'interfaces/IMarket';
import { IProperty } from 'interfaces/IProperty';
import { ICountry } from 'interfaces/ICountry';
import locations from 'routes';

import { getMultipleMarketsNamesShort } from './utils';
import styles from './MultipleMarketsLink.module.scss';

interface Props {
  markets: IMarket[];
  preselectedMarket?: IMarket;
  properties?: IProperty[];
}

interface Market {
  id: number;
  country: ICountry | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  mediaUrl: string | undefined;
  name: string | undefined;
}

const MultipleMarketsLink: React.FC<Props> = ({
  markets,
  properties,
  preselectedMarket,
}) => {
  const [isModalLinkVisible, setIsModalLinkVisible] = useState(false);
  const formattedMarkets: Market[] = markets.map(market => ({
    id: market.id,
    country: market.country,
    latitude: market.latitude,
    longitude: market.longitude,
    mediaUrl: market.mediaUrl,
    name: market.name.replace(/Z\s*-\s*/, ''),
  }));

  const hasMultipleMarkets = formattedMarkets.length > 1;

  return (
    <>
      {hasMultipleMarkets ? (
        <div className={styles['address-container']}>
          <div
            className={styles['market-address']}
            onClick={() => setIsModalLinkVisible(true)}
          >
            {' '}
            {getMultipleMarketsNamesShort(formattedMarkets, preselectedMarket)}
          </div>
          <ModalMarketsLinks
            isVisible={isModalLinkVisible}
            markets={formattedMarkets}
            properties={properties ?? []}
            onClose={() => setIsModalLinkVisible(false)}
          />
        </div>
      ) : (
        <AYLink
          to={locations.showMarket(formattedMarkets[0].id!)}
          wrapperClassName={styles['market-address']}
        >
          {formattedMarkets[0].name}
        </AYLink>
      )}
    </>
  );
};

export default MultipleMarketsLink;
